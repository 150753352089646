
<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h2 class="title">Paypal Payment</h2>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item :href="paypalSubscriptionLink">
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Paypal Subscription</v-list-item-title>
          </v-list-item>
          <v-list-item target="_blank" color="red" v-if="active" @click="dialogDelete = true">
            <v-list-item-icon>
              <v-icon color="red">mdi-cancel</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Abo entfernen</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="debug">
            {{ subscription }}
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Abo verschieben</v-card-title>
        <v-card-text> Abgelaufenes Abo wirklich in die History verschieben? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn color="blue darken-1" text @click="cancelSubscription"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: {
    subscription: Object,
    uid: String,
  },
  data(){
      return {
          dialogDelete:false
      };
  },
  components: {},
  computed: {
    ...mapGetters({
      debug: "navigation/debug",
    }),
    paypalSubscriptionLink() {
      const id = this.subscription.id;
      return "https://www.paypal.com/billing/subscriptions/" + id;
    },
    active(){
        if(this.subscription.cancel_at_period_end === false){
            return true;
        }
        let now = moment();
        let end = moment(this.subscription.current_period_end.toDate());

       

        console.log({now, end, diff:now.diff(end)});
        if(now.diff(end) > 0){
            return true;
        }

        return false;
    }
    
  },
  created: function () {
    // this.$store.dispatch("setCurrentView", "Login");
  },
  methods: {
    dateFromTimestamp(stamp) {
      return moment.unix(stamp).format("DD.MM.YYYY");
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price / 100) + " €";
    },


    cancelSubscription() {
        this.$store.dispatch("user/cancelSubscription", {
        subscription: this.subscription,
        uid: this.uid,
      });
    },
  },
};
</script>
