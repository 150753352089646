<template>
  <div>
    <v-sheet>
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>Geräte</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row dense>
          <v-col :cols="12">
            <v-card>
              <v-card-text>
                <v-data-table
                  id="virtual-scroll-table"
                  :headers="headers"
                  :items="devices"
                  :hide-default-footer="true"
                  :disable-pagination="true"
                  show-expand
                  class="elevation-1"
                >
                  <template v-slot:[`item.id`]="{ item }">
                    {{ item.id.substring(0, 8) }}
                  </template>
                  <template v-slot:[`item.lastSeen`]="{ item }">
                    {{ item.lastSeen.toDate() | formatDate }}
                  </template>
                  <template v-slot:[`item.osVersion`]="{ item }">
                    <v-icon v-if="item.os.toLowerCase() == 'android'"
                      >android</v-icon
                    >
                    <v-icon v-else-if="item.os.substring(0, 1) == 'i'"
                      >$apple</v-icon
                    >
                    <span v-else>{{ item.os }}</span>

                    {{ item.osVersion }}
                  </template>
                  <template v-slot:[`item.active`]="{ item }">
                    <v-icon color="green" v-if="item.active"
                      >mdi-check-circle-outline</v-icon
                    >
                    <v-icon color="red" v-else>mdi-close</v-icon>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      Mehr zu {{ item.name }};
                      <pre>{{ item }}</pre>
                    </td>
                  </template>
                </v-data-table>
                <v-row v-if="debug">
                  <v-col cols="6" v-for="device in devices" :key="device.id">
                    <pre>[{{ device }}]</pre>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    devices: {
      type: Array,
      default: () => [], // <= here also
    },
    uid: String,
  },
  created: async function () {},
  computed: {
    ...mapGetters({
      debug: "navigation/debug",
    }),
    isDirty: function () {
      return (
        JSON.stringify(this.originalData) !=
        JSON.stringify(this.editableDevices)
      );
    },
  },
  data() {
    return {
      dirty: false,
      originalData: { ...this.devices },
      editableDevices: { ...this.devices },
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "OS",
          align: "start",
          sortable: false,
          value: "osVersion",
        },
        {
          text: "App",
          align: "start",
          sortable: false,
          value: "appVersion",
        },
        {
          text: "zuletzt online",
          align: "start",
          sortable: false,
          value: "lastSeen",
        },
        {
          text: "aktiv",
          align: "start",
          sortable: false,
          value: "active",
        },
      ],
    };
  },
  methods: {
    save: function () {
      this.$store.dispatch("user/setDevices", {
        devices: this.editableDevices,
        uid: this.uid,
      });
    },
  },
};
</script>