<template>
  <div>
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container v-else>
      <v-alert v-if="error" type="error">{{ error }}</v-alert>

      <v-sheet>
        <v-toolbar color="secondary" dark>
          <v-toolbar-title>Nutzer {{ user.username }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col :cols="12">
              <v-card>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="6">
                      <v-row dense>
                        <v-col :cols="3">User:</v-col>
                        <v-col>{{ user.username }}</v-col>
                      </v-row>
                      <v-row dense>
                        <v-col :cols="3">Status:</v-col>
                        <v-col>{{ user.active ? "aktiv" : "inaktiv" }}</v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>Typ: {{ user.type }}</v-col>
                        <v-col>
                          <v-select
                            label="Typ:"
                            v-model="editableUser.type"
                            :items="userTypes"></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row dense>
                        <v-col :cols="3">Id:</v-col>
                        <v-col>{{ id }}</v-col>
                      </v-row>
                      <v-row dense>
                        <v-col :cols="3">Seit:</v-col>
                        <v-col>
                          {{
                            user.created
                              ? formatDate(user.created.toDate())
                              : "N/A"
                          }}
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col :cols="3">E-Mail bestätigt:</v-col>
                        <v-col v-if="user.emailConfirmed">
                          <v-icon color="green">
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                        </v-col>
                        <v-col v-else>
                          <v-icon color="red">
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                          <v-btn
                            class="ml-2 white--text"
                            color="green"
                            elevation="2"
                            @click="confirmEmail(user.confirmationHash)">
                            <v-icon color="white">mdi-email-open</v-icon>
                            E-Mailadresse bestätigen
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-btn
                            v-if="typeDirty"
                            color="green"
                            elevation="2"
                            @click="saveUserType">
                            <v-icon color="white">mdi-content-save</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-container>
      </v-sheet>
      <UserSettings :settings="user.settings" :uid="id"></UserSettings>
      <v-sheet v-if="user.current_subscription">
        <v-toolbar color="secondary" dark>
          <v-toolbar-title>Abo</v-toolbar-title>
        </v-toolbar>
        <v-row>
          <v-col cols="6">
            <Subscription :subscription="user.current_subscription" />
          </v-col>
          <v-col v-if="user.current_subscription" cols="6">
            <StripePayment
              v-if="
                user.payment &&
                user.payment.stripe &&
                user.payment.stripe.subscription
              "
              :subscription="user.payment.stripe.subscription" />
            <StripePayment
              v-if="
                (!user.payment || !user.payment.stripe) &&
                user.current_subscription.payment_provider === 'stripe'
              "
              :subscription="user.current_subscription" />
            <PaypalPayment
              v-if="user.current_subscription.payment_provider === 'paypal'"
              :subscription="user.current_subscription"
              :uid="id" />
            <CustomPayment
              v-if="user.current_subscription.payment_provider === 'custom'"
              :subscription="user.current_subscription"
              :uid="id"></CustomPayment>
            <VoucherPayment
              v-if="user.current_subscription.payment_provider === 'voucher'"
              :subscription="user.current_subscription"
              :uid="id"></VoucherPayment>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet v-if="!user.current_subscription">
        <v-toolbar color="secondary" dark>
          <v-toolbar-title>Benutzerdefiniertes Abo buchen</v-toolbar-title>
        </v-toolbar>
        <CustomSubscriptionForm :uid="id"></CustomSubscriptionForm>
      </v-sheet>
      <UserDevices :devices="user.devices" :uid="id"></UserDevices>
      <v-sheet>
        <v-toolbar color="secondary" dark>
          <v-toolbar-title>Vorherige Abos</v-toolbar-title>
        </v-toolbar>
        <v-row v-if="user.subscription_history">
          <v-col
            cols="6"
            v-for="subscription in user.subscription_history"
            v-bind:key="subscription.id">
            <Subscription :subscription="subscription" />
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header color="warning">
              Administration
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-sheet>
                <v-row>
                  <v-col>
                    <v-btn @click="dialogDelete = true" color="error">
                      <v-icon>mdi-delete</v-icon>
                      Account löschen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
      <v-divider></v-divider>
      <v-sheet
        v-if="
          user.payment && user.payment.stripe && user.payment.stripe.history
        ">
        <v-toolbar color="secondary" dark>
          <v-toolbar-title>Vergangene Stripe-Zahlungen</v-toolbar-title>
        </v-toolbar>
        <v-row>
          <v-col
            :cols="6"
            v-for="(item, i) in user.payment.stripe.history"
            :key="i">
            <StripePayment :subscription="item" />
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet v-if="debug">
        <v-container>
          <v-card>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>Rohdaten</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <code>{{ jsonUser }}</code>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-container>
      </v-sheet>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Kundenkonto löschen?</v-card-title>
          <v-card-text>
            Kundenkonto {{ id }} von {{ user.username }} wirklich
            <strong>unwiederruflich</strong>
            löschen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDelete = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteUserAccount">
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapGetters } from "vuex";
import moment from "moment";
import StripePayment from "@/components/StripePayment";
import PaypalPayment from "@/components/PaypalPayment";
import CustomPayment from "@/components/CustomPayment";
import VoucherPayment from "@/components/VoucherPayment";
import Subscription from "@/components/Subscription";
import UserSettings from "@/components/UserSettings";
import UserDevices from "@/components/UserDevices";
import CustomSubscriptionForm from "@/components/CustomSubscriptionForm";

export default {
  components: {
    StripePayment,
    PaypalPayment,
    CustomPayment,
    VoucherPayment,
    Subscription,
    UserSettings,
    CustomSubscriptionForm,
    UserDevices,
  },
  data() {
    return {
      id: this.$route.params.id,
      dialogDelete: false,
      userTypes: [
        { value: "private", text: "Privat" },
        { value: "school", text: "Schule" },
      ],
      editableUser: { ...this.user },
    };
  },
  created: function () {
    this.$store.dispatch("user/fetch", { uid: this.$route.params.id });
  },
  watch: {
    $route(to, from) {
      if (to.params.id != from.params.id) {
        this.id = to.params.id;
        this.$store.dispatch("user/fetch", { uid: to.params.id });
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: "user/loading",
      user: "user/get",
      error: "user/error",
      debug: "navigation/debug",
    }),
    jsonUser() {
      return JSON.parse(JSON.stringify(this.user));
    },
    typeDirty() {
      return this.user.type != this.editableUser.type;
    },
  },
  methods: {
    async deleteUserAccount() {
      this.dialogDelete = false;
      await this.$store.dispatch("user/delete", { uid: this.$route.params.id });
      console.log("gohome");
      this.$router.push({ name: "home" });
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    dateFromTimestamp(stamp) {
      return moment.unix(stamp).format("DD.MM.YYYY");
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price / 100) + " €";
    },
    stripeLink(id, type, live) {
      // https://dashboard.stripe.com/test/subscriptions/sub_GM2BU5nTwRH4D9
      let link = "https://dashboard.stripe.com/";
      if (!live) {
        link += "test/";
      }
      link += type + "/" + id;
      return link;
    },
    confirmEmail(confirmationHash) {
      this.$store.dispatch("user/confirmEmail", {
        hash: confirmationHash,
        uid: this.$route.params.id,
      });
    },
    async saveUserType() {
      console.log({
        uid: this.$route.params.id,
        type: this.editableUser.type,
      });
      await this.$store.dispatch("user/setUserType", {
        uid: this.$route.params.id,
        type: this.editableUser.type,
      });
    },
  },
};
</script>
