<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h2 class="title">Stripe Payment</h2>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item :href="stripeAccountLink">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Stripe Customer</v-list-item-title>
          </v-list-item>
          <v-list-item :href="stripeSubscriptionLink">
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Stripe Subscription</v-list-item-title>
          </v-list-item>
          <v-list-item :href="stripeProductLink" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-shopping</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Stripe Product</v-list-item-title>
          </v-list-item>

          <v-list-item
            :href="stripeProductLink"
            target="_blank"
            color="red"
            :input-value="true"
          >
            <v-list-item-icon>
              <v-icon color="red">mdi-cancel</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Abo kündigen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  props: {
    subscription: Object,
  },
  components: {},
  computed: {
    live() {
      return this.subscription.livemode;
    },
    stripeAccountLink() {
      const id = this.subscription.customer;
      const type = "customers";
      return this.stripeLink(id, type);
    },
    stripeSubscriptionLink() {
      const id = this.subscription.id;
      const type = "subscriptions";
      return this.stripeLink(id, type);
    },
    stripeProductLink() {
      if (!this.subscription.product) {
        return "";
      }
      const id = this.subscription.product.id;
      const type = "subscriptions/products";
      return this.stripeLink(id, type);
    },
  },
  created: function () {
    // this.$store.dispatch("setCurrentView", "Login");
  },
  methods: {
    dateFromTimestamp(stamp) {
      return moment.unix(stamp).format("DD.MM.YYYY");
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price / 100) + " €";
    },
    stripeLink(id, type) {
      // https://dashboard.stripe.com/test/subscriptions/sub_GM2BU5nTwRH4D9
      let link = "https://dashboard.stripe.com/";
      if (!this.live) {
        link += "test/";
      }
      link += type + "/" + id;
      return link;
    },
    cancelSubscription() {},
  },
};
</script>
