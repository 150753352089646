<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h2 class="title">Gutschein Zahlung</h2>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item target="_blank" color="red" @click="dialogDelete = true">
            <v-list-item-icon>
              <v-icon color="red">mdi-cancel</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Abo kündigen</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="debug">{{ subscription }}</v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Kündigung</v-card-title>
        <v-card-text> Benutzerdefiniertes Abo wirklich kündigen? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn color="blue darken-1" text @click="cancelSubscription"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return { dialogDelete: false };
  },
  props: {
    subscription: Object,
    uid: String,
  },
  components: {},
  computed: {
    ...mapGetters({ debug: "navigation/debug" }),
  },
  created: function () {
    // this.$store.dispatch("setCurrentView", "Login");
  },
  methods: {
    dateFromTimestamp(stamp) {
      return moment.unix(stamp).format("DD.MM.YYYY");
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price / 100) + " €";
    },

    cancelSubscription() {
      this.$store.dispatch("customSubscription/cancelCustomSubscription", {
        subscription: this.subscription,
        uid: this.uid,
      });
    },
  },
};
</script>
