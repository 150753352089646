<template>
  <v-container class="campaign-data">
    <v-row>
      <v-col cols="6" md="6">
        <v-card>
          <v-card-title>
            <h2>{{ campaign.name }}</h2>
          </v-card-title>
          <v-card-text>
            <p>{{ campaign.description }}</p>
            <p>Erstellt am: {{ getFormatedDate(campaign.createdAt) }}</p>
            <p>
              Kampagnenzeitraum:
              {{ getDateFromFSTimestamp(campaign.campaignStart) }} -
              {{ getDateFromFSTimestamp(campaign.campaignEnd) }}
            </p>
            <p>
              Eingelöste Gutscheine: {{ campaign.vouchersMetrics.redeemed }} /
              {{ campaign.vouchersMetrics.total }} ({{ percentRedeemed }}%)
            </p>
            <p>
              Eingelöster Gutscheinwert monatlich: {{ valueRedeemedMonthly }} €
            </p>
            <p>Eingelöster Gutscheinwert total: {{ valueRedeemedTotal }} €</p>
            <p>
              Kampagenendaten von {{ getFormatedDateTime(campaignUpdated) }}
            </p>
            <p>
              <v-btn @click="fetchCampaignData()">Daten Aktualisieren</v-btn>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            <h2>Statistik</h2>
          </v-card-title>
          <v-card-text>
            <Pie :data="pieData" :options="pieOptions" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <h2>Gutscheine</h2>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="voucherTableHeaders"
              :items="campaign.vouchers"
              :items-per-page="100"
              class="elevation-1"
            >
              <template v-slot:[`item.handedOut`]="{ item }" >
                
                <v-icon color="green"  v-if="item.handedOut"  @click="toggleHandedOut(item)" :title="'Am: '+getDateFromFSTimestamp(item.handedOutAt)"
                  >mdi-check-circle-outline</v-icon>
                <v-icon color="red" v-else @click="toggleHandedOut(item)">mdi-close</v-icon>
              </template>
              <template v-slot:[`item.redeemed`]="{ item }">
                <v-icon color="green" v-if="item.redeemed"
                  >mdi-check-circle-outline</v-icon
                >
                <v-icon color="red" v-else>mdi-close</v-icon>
              </template>
              <template v-slot:[`item.redeemedAt`]="{ item }">
                {{ getDateFromFSTimestamp(item.redeemedAt) }}
              </template>
              <template v-slot:[`item.redeemedBy`]="{ item }">
                <router-link
                  v-if="typeof item.redeemedBy == 'string'"
                  :to="{ name: 'user-detail', params: { id: item.redeemedBy } }"
                >
                  {{ item.redeemedBy }}</router-link
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

import { mapGetters } from "vuex";
import moment from "moment";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "campaign",
  components: {
    Pie,
  },
  data: function () {
    return {
      voucherTableHeaders: [
        { text: "Gutscheincode", value: "code" },
        { text: "Ausgegeben", value: "handedOut" },
        { text: "Eingelöst", value: "redeemed" },
        // sort by date
        {
          text: "Eingelöst am",
          value: "redeemedAt",
          sort: (a, b) => {
            if (a === null || a == "") return -1;
            if (b === null || b == "") return 1;
            if (a === b) return 0;
            return new Date(a.seconds * 1000) > new Date(b.seconds * 1000)
              ? 1
              : -1;
          },
        },
        { text: "Eingelöst von", value: "redeemedBy" },
      ],
      pieOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      campaign: "campaigns/getCampaign",
      campaignUpdated: "campaigns/getCampaignUpdated",
      error: "campaigns/getError",
    }),
    pieData() {
      return {
        labels: ["Eingelöst", "Nicht eingelöst"],
        datasets: [
          {
            label: "Eingelöste Gutscheine",
            data: [
              this.campaign.vouchersMetrics.redeemed,
              this.campaign.vouchersMetrics.total -
                this.campaign.vouchersMetrics.redeemed,
            ],
            backgroundColor: ["#4CAF50", "#FFC107"],
          },
        ],
      };
    },
    percentRedeemed() {
      return (
        (this.campaign.vouchersMetrics.redeemed /
          this.campaign.vouchersMetrics.total) *
        100
      ).toFixed(2);
    },
    valueRedeemedMonthly() {
      return ((this.campaign.vouchersMetrics.redeemed * 99.95) / 12).toFixed(2);
    },
    valueRedeemedTotal() {
      return (this.campaign.vouchersMetrics.redeemed * 99.95).toFixed(2);
    },
  },

  methods: {
    getFormatedDate(date) {
      let formattedDate = moment(date).format("DD.MM.YYYY");
      return formattedDate;
    },
    getFormatedDateTime(date) {
      let formattedDate = moment(date).format("DD.MM.YYYY HH:mm");
      return formattedDate;
    },
    getDateFromFSTimestamp(timestamp) {
      if (typeof timestamp === "undefined" || timestamp === null) {
        return "";
      }
      let date = new Date(timestamp.seconds * 1000);
      return moment(date).format("DD.MM.YYYY");
    },
    fetchCampaignData() {
      this.$store.dispatch("campaigns/fetchCampaign", {
        id: this.$route.params.id,
        force: true,
      });
    },
    toggleHandedOut(item) {
      this.$store.dispatch("campaigns/toggleHandedOut", {
        voucherId: item.id,
        handedOut: !item.handedOut,
      });
    },
  },

  mounted() {
    console.log("CampaignData.vue mounted");
  },
  created() {
    this.$store.dispatch("campaigns/fetchCampaign", {
      id: this.$route.params.id,
    });
    console.log("CampaignData.vue created");
  },
};
</script>