<template>
  <div>
    <v-sheet>
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>App Einstellungen</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row dense>
          <v-col :cols="12">
            <v-card>
              <v-card-title>
                <h2 class="title">Buttons anzeigen:</h2>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-row dense>
                      <v-col>
                        <v-switch
                          inset
                          v-model="editableSettings.showLogoutButton"
                          label="Logout"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <v-switch
                          inset
                          v-model="editableSettings.showSettingsButton"
                          label="Settings"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <v-switch
                          inset
                          v-model="editableSettings.showDeleteAccountButton"
                          label="Account löschen"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <v-switch
                          inset
                          v-model="editableSettings.showInAppPurchase"
                          label="In App Purchase"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <v-switch
                          inset
                          v-model="editableSettings.showRedeemVoucherButton"
                          label="Gutschein einlösen Button"
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col :cols="10"></v-col>
                      <v-col :cols="1">
                        <v-btn
                          v-if="isDirty"
                          color="green"
                          elevation="2"
                          @click="save"
                        >
                          <v-icon color="white">mdi-content-save</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="debug">
                  <v-col cols="4">
                    <pre>[{{ settings }}]</pre>
                  </v-col>
                  <v-col cols="4">
                    <pre>[{{ originalData }}]</pre>
                  </v-col>
                  <v-col cols="4">
                    <pre>[{{ isDirty }}]</pre>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    settings: {
      type: Object,
      default: () => ({
        // <= note the parenthesis
        showLogoutButton: true,
        showSettingsButton: true,
        showDeleteAccountButton: true,
        showInAppPurchase: true,
        showRedeemVoucherButton: true,
      }), // <= here also
    },
    uid: String,
  },
  created: async function () {},
  computed: {
    ...mapGetters({
      debug: "navigation/debug",
    }),
    isDirty: function () {
      return (
        JSON.stringify(this.originalData) !=
        JSON.stringify(this.editableSettings)
      );
    },
  },
  data() {
    return {
      dirty: false,
      originalData: { ...this.settings },
      editableSettings: { ...this.settings },
    };
  },
  methods: {
    save: function () {
      this.$store.dispatch("user/setSettings", {
        settings: this.editableSettings,
        uid: this.uid,
      });
    },
  },
};
</script>