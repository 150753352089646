<template>
  <v-container>
    <v-img v-if="loaded" :src="link" :width="width"> </v-img>
  </v-container>
</template>

<script>
import { storage } from "@/plugins/firebase";

export default {
  props: {
    url: { type: String, required: true },
    width: { type: Number, default: 300 },
  },
  data: function () {
    return { src: "", loaded: false };
  },
  components: {},
  computed: {},
  created: function () {
    this.getLink();
  },
  methods: {
    getLink() {
      storage
        .ref(this.url)
        .getDownloadURL()
        .then((link) => {
          this.link = link;
          this.loaded = true;
        });
    },
  },
};
</script>
