<template>
  <div class="word">
    <v-container>
      <v-overlay v-if="saving || loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-alert v-if="error != ''" type="error">{{ error }}</v-alert>
      <!-- <pre>{{ editableWord }}</pre> -->
      <div v-if="!loading && Object.keys(editableWord).length > 0">
        <v-row>
          <v-col cols="10">
            <span>{{ editableWord.word }}</span>
            <pre>[{{ id }}]</pre>
          </v-col>
          <v-col cols="2"
            ><v-btn elevation="2" @click="$router.go(-1)">zurück</v-btn></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-chip-group>
              <v-chip v-if="isNew" color="orange">Neues Wort</v-chip>
              <v-chip v-if="dirty" color="orange"
                >Änderungen nicht gespeichert</v-chip
              >
              <v-chip
                v-if="isDuplicate"
                color="red"
                :to="{ name: 'word-detail', params: { id: isDuplicate.id } }"
                >Wort existiert bereits</v-chip
              >
              <!-- <v-chip :color="saving ? 'green' : 'orange'">saving</v-chip>
              <v-chip :color="loading ? 'green' : 'orange'">loading</v-chip> -->
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="4">
            <v-text-field
              v-model="editableWord.word"
              full-width
              hide-details="auto"
              label="Wort"
              single-line
            ></v-text-field>
          </v-col>
        </v-row>
        <div v-if="!isNew">
          <v-row>
            <v-col cols="12">
              <v-card class="mx-2">
                <v-container class="py-0">
                  <v-card-title>Meta</v-card-title>
                  <v-row>
                    <v-col cols="4">
                      <v-switch
                        v-model="editableWord.active"
                        hide-details="auto"
                        label="Veröffentlicht"
                        single-line
                        inset
                        color="success"
                      ></v-switch>
                    </v-col>
                    <v-col cols="4">
                      <v-switch
                        v-model="editableWord.double_sign"
                        hide-details="auto"
                        label="Doppelgebärde"
                        single-line
                        inset
                        color="success"
                      ></v-switch>
                    </v-col>
                    <v-col cols="4">
                      <v-switch
                        v-model="editableWord.hide_from_all_words"
                        hide-details="auto"
                        label="In alle Wörter verstecken"
                        single-line
                        inset
                        color="success"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card> </v-col
          ></v-row>
          <v-row>
            <v-col cols="12">
              <v-card class="mx-2 categories--card">
                <v-container class="py-0">
                  <v-card-title>Kategorien</v-card-title>
                  <v-row>
                    <v-col cols="12">
                      <v-chip-group column>
                        <v-chip
                          v-for="item in availableCategories"
                          :key="item.id"
                          @click="toggleCategory(item.id)"
                          label
                          :color="
                            selectedCategories.includes(item) ? 'green' : ''
                          "
                        >
                          <v-icon
                            v-if="item.show_in_app"
                            :disabled="loading"
                            title="In App angezeigt"
                            >mdi-cellphone</v-icon
                          >
                          {{ item.name }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card class="mx-2 dictionaries--card">
                <v-container class="py-0">
                  <v-card-title>Wörterbücher</v-card-title>
                  <v-row>
                    <v-col cols="12">
                      <v-chip-group column>
                        <v-chip
                          v-for="item in availableDictionaries"
                          :key="item.id"
                          @click="toggleDictionary(item.id)"
                          label
                          :color="
                            selectedDictionaries.includes(item) ? 'green' : ''
                          "
                        >
                          <v-icon
                            v-if="item.show_in_app"
                            :disabled="loading"
                            title="In App angezeigt"
                            >mdi-cellphone</v-icon
                          >
                          {{ item.name }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-card
                class="mx-2"
                v-if="editableWord.symbol && editableWord.symbol.filePath"
              >
                <v-card-title>Symbol</v-card-title>
                <v-card-text>
                  <FirebaseStorageImg
                    v-if="editableWord.symbol"
                    :url="editableWord.symbol.filePath"
                  ></FirebaseStorageImg>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="error" @click="dialogDeleteSymbol = true" tile
                    >Löschen</v-btn
                  >
                </v-card-actions>
              </v-card>
              <v-card class="mx-2" v-else>
                <v-card-title>Symbol</v-card-title>
                <v-card-text>
                  <v-file-input
                    v-model="addSymbolFile"
                    label="Symbol Datei"
                  ></v-file-input>
                  <!-- <pre>{{ addSymbolFile }}</pre> -->
                </v-card-text>
                <v-card-actions>
                  <v-btn color="success" @click="addSymbol">Hinzufügen</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card class="mx-2">
                <v-card-title>Audio</v-card-title>
                <v-list>
                  <v-card
                    v-for="audio of editableWord.audios"
                    :key="audio.filePath"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <audio
                          v-firebase-storage-src="audio.filePath"
                          controls="controls"
                          style="width: 100%"
                        ></audio>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <pre>{{ audio.voice }}</pre>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <pre>{{ audio.filePath }}</pre>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn color="error" @click="deleteAudio(audio)" tile
                          >Löschen</v-btn
                        >
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-card>
                  <v-card
                    v-if="
                      !editableWord.audios || editableWord.audios.length < 1
                    "
                  >
                    <v-card-subtitle>Audio hinzufügen:</v-card-subtitle>
                    <v-container>
                      <v-card-text>
                        <v-select
                          v-model="addAudioInfo.voice"
                          :items="['boy', 'girl']"
                        ></v-select>

                        <v-file-input
                          v-model="addAudioFile"
                          label="Audio Datei"
                        ></v-file-input>
                        <pre>{{ addAudioFile }}</pre>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="success" @click="addAudio"
                          >Hinzufügen</v-btn
                        >
                      </v-card-actions>
                    </v-container>
                  </v-card>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="video of editableWord.videos"
              :key="video.filePath"
              cols="12"
            >
              <v-card>
                <v-card-title>Video</v-card-title>
                <video
                  v-firebase-storage-src="video.filePath"
                  controls="controls"
                  style="width: 100%"
                ></video>
                <v-card-subtitle class="pb-0">
                  <pre>{{ video.filePath }}</pre>
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn color="error" @click="deleteVideo(video)" tile
                    >Löschen</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              v-if="!editableWord.videos || editableWord.videos.length < 1"
            >
              <v-card>
                <v-card-subtitle>Video hinzufügen:</v-card-subtitle>
                <v-container>
                  <v-card-text>
                    <v-select
                      v-model="addVideoInfo.language"
                      :items="['dgs']"
                    ></v-select>

                    <v-file-input
                      v-model="addVideoFile"
                      label="Video Datei"
                    ></v-file-input>
                    <pre>{{ addVideoFile }}</pre>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="success" tile @click="addVideo"
                      >Hinzufügen</v-btn
                    >
                  </v-card-actions>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-subtitle>Wort löschen:</v-card-subtitle>
                <v-container>
                  <v-card-actions>
                    <v-btn color="error" tile @click="deleteWord"
                      >Wort löschen</v-btn
                    >
                  </v-card-actions>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-btn v-if="dirty" color="green" elevation="2" @click="save"
            ><v-icon color="white">mdi-content-save</v-icon>
          </v-btn>
        </div>
        <v-btn
          v-if="dirty"
          color="green"
          elevation="2"
          fab
          fixed
          right
          bottom
          @click="save"
        >
          <v-icon color="white">mdi-content-save</v-icon>
        </v-btn>
        <v-text-field :value="wordJson"> </v-text-field>
      </div>
      <v-dialog v-model="dialogDeleteSymbol" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Symbol wirklich löschen?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogDeleteSymbol = false"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteSymbol">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDeleteAudio" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Audio wirklich löschen?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDeleteAudio = false"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteAudio">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDeleteVideo" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Video wirklich löschen?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDeleteVideo = false"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteVideo">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDeleteWord" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Gesamtes Wort "{{ editableWord.word }}" mit allen Dateien wirklich
            löschen?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDeleteWord = false"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteWord">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<style scoped>
.dictionaries--card {
  height: 200px;
  overflow: auto;
}
.categories--card {
  height: 500px;
  overflow: auto;
}
</style>

<script>
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

import { mapGetters } from "vuex";
import FirebaseStorageImg from "@/components/FirebaseStorageImg";

export default {
  name: "edit-word",
  components: { FirebaseStorageImg },
  created: async function () {
    if (this.$route.params.id === undefined) {
      this.$store.dispatch("word/createWord");
      return;
    }

    this.$store.dispatch("word/fetchWord", { id: this.$route.params.id });
  },
  data: function () {
    return {
      id: "",
      editableWord: {},
      editableWordJson: "",
      addAudioFile: [],
      addAudioInfo: {
        voice: "",
        file: "",
        type: "",
      },
      addVideoFile: [],
      addVideoInfo: {
        language: "",
        file: "",
        type: "",
      },
      addSymbolFile: [],
      //   loading: true,
      search: "",
      searchDict: "",
      allSelected: false,
      selectedCategories: [],
      selectedDictionaries: [],
      counter: 0,
      dialogDeleteSymbol: false,
      dialogDeleteAudio: false,
      dialogDeleteVideo: false,
      dialogDeleteWord: false,
      deleteVideoId: "",
      deleteAudioId: "",
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      dictionariesList: "dictionaries/getList",
      categoriesList: "categories/getList",
      wordObject: "word/word",
      wordJson: "word/json",
      words: "words/getList",

      saving: "word/saving",
      loading: "word/loading",
      error: "word/error",
      dirty: "word/dirty",
    }),
    isNew() {
      return this.$route.params.id === undefined;
    },
    availableCategories() {
      const search = this.search.toLowerCase();
      //   v-if="!selectedCategories.includes(item)"
      let categories = this.categoriesList;
      //    categories = this.categoriesList.filter((item) => {
      //     return !this.selectedCategories.includes(item);
      //   });
      if (!search) return categories;
      return categories.filter((item) => {
        const text = item.name.toLowerCase();
        return text.indexOf(search) > -1;
      });
    },
    availableDictionaries() {
      const search = this.searchDict.toLowerCase();
      if (!search) return this.dictionariesList;
      return this.dictionariesList.filter((item) => {
        const text = item.name.toLowerCase();
        return text.indexOf(search) > -1;
      });
    },

    categoriesSelections() {
      const selections = [];
      for (const selection of this.selectedCategories) {
        selections.push(selection);
      }
      return selections;
    },
    dictionariesSelections() {
      const selections = [];
      for (const selection of this.selectedDictionaries) {
        selections.push(selection);
      }
      return selections;
    },
    isDuplicate() {
      if (!this.isNew) {
        return false;
      }
      const doublettes = this.words.filter(
        (word) =>
          word.word.toLowerCase() == this.editableWord.word.toLowerCase()
      );
      const duplicat = doublettes.length > 0;
      console.log(doublettes, duplicat);

      return duplicat ? doublettes[0] : false;
    },
  },
  watch: {
    wordObject(after) {
      const editableWord = {
        id: after.id,
        active: after.active,
        word: after.word,
        audios: after.audios,
        videos: after.videos,
        symbol: after.symbol,
        categories: after.categories || [],
        categories_ids: after.categories_ids || [],
        dictionaries: after.dictionaries || [],
        dictionaries_ids: after.dictionaries_ids || [],
        double_sign: after.double_sign || false,
        hide_from_all_words: after.hide_from_all_words || false,
      };
      this.editableWordJson = JSON.stringify(editableWord);
      this.editableWord = JSON.parse(this.editableWordJson);
      if (after.id) {
        this.id = after.id;
      }
    },
    editableWord: {
      deep: true,
      handler(after) {
        if (this.categoriesList) {
          this.selectedCategories = this.categoriesList.filter(
            (cat) =>
              after.categories_ids && after.categories_ids.includes(cat.id)
          );
          console.log(
            "selectedCatgories",
            this.selectedCategories,
            after.categories_ids
          );
        }
        if (this.dictionariesList) {
          this.selectedDictionaries = this.dictionariesList.filter(
            (dict) =>
              after.dictionaries_ids && after.dictionaries_ids.includes(dict.id)
          );
        }

        console.log(
          "edited",
          this.editableWord,
          this.editableWordJson,
          JSON.stringify(after)
        );
        if (
          this.editableWordJson != JSON.stringify(after) &&
          this.counter < 20
        ) {
          console.log("editedd");
          this.counter++;
          this.$store.dispatch("word/updateWord", {
            word: { ...this.editableWord },
          });
        }
      },
    },
  },

  methods: {
    save() {
      if (this.isNew) {
        this.$store.dispatch("word/addWord", {
          word: this.editableWord,
        });

        return;
      }
      this.$store.dispatch("word/saveWord", {
        id: this.$route.params.id,
        word: this.editableWord,
      });
    },
    addAudio() {
      if (this.addAudioFile instanceof File) {
        //addAudio({ commit, dispatch }, { word, file, fileInfo }) {
        this.$store.dispatch("word/addAudio", {
          file: this.addAudioFile,
          word: this.editableWord,
          fileInfo: this.addAudioInfo,
        });
      }
    },
    deleteAudio(audio) {
      if (!this.dialogDeleteAudio == true) {
        console.log(audio);
        this.deleteAudioId = audio.id;
        this.dialogDeleteAudio = true;
        return;
      }
      this.$store.dispatch("word/deleteAudio", {
        word: this.editableWord,
        id: this.deleteAudioId,
      });

      this.dialogDeleteAudio = false;
    },
    addVideo() {
      console.log("addVideooo");
      if (this.addVideoFile instanceof File) {
        //addAudio({ commit, dispatch }, { word, file, fileInfo }) {
        this.$store.dispatch("word/addVideo", {
          file: this.addVideoFile,
          word: this.editableWord,
          fileInfo: this.addVideoInfo,
        });
      }
    },
    deleteVideo(video) {
      if (!this.dialogDeleteVideo) {
        this.deleteVideoId = video.id;
        this.dialogDeleteVideo = true;
        return;
      }
      this.$store.dispatch("word/deleteVideo", {
        word: this.editableWord,
        id: this.deleteVideoId,
      });
      this.dialogDeleteVideo = false;
    },
    deleteWord() {
      if (!this.dialogDeleteWord) {
        // this.deleteWord = {...this.editableWord};
        this.dialogDeleteWord = true;
        return;
      }
      this.$store.dispatch("word/deleteWord", {
        word: this.editableWord,
      });
      this.dialogDeleteVideo = false;
    },
    addSymbol() {
      if (this.addSymbolFile instanceof File) {
        this.$store.dispatch("word/addSymbol", {
          file: this.addSymbolFile,
          word: this.editableWord,
        });
      }
    },
    deleteSymbol() {
      if (this.editableWord.symbol && this.editableWord.symbol.filePath) {
        this.$store.dispatch("word/deleteSymbol", {
          word: this.editableWord,
        });
        this.dialogDeleteSymbol = false;
      }
    },
    toggleCategory(id) {
      if (
        this.editableWord.categories_ids &&
        this.editableWord.categories_ids.indexOf(id) >= 0
      ) {
        this.editableWord.categories_ids =
          this.editableWord.categories_ids.filter((catId) => id !== catId);
        return;
      }
      this.editableWord.categories_ids.push(id);
    },

    addCategory(id) {
      if (
        this.editableWord.categories_ids &&
        this.editableWord.categories_ids.indexOf(id) >= 0
      ) {
        return;
      }
      this.editableWord.categories_ids.push(id);

      console.log(id);
    },
    removeCategory(id) {
      this.editableWord.categories_ids =
        this.editableWord.categories_ids.filter((catId) => id !== catId);
    },
    toggleDictionary(id) {
      if (
        this.editableWord.dictionaries_ids &&
        this.editableWord.dictionaries_ids.indexOf(id) >= 0
      ) {
        this.editableWord.dictionaries_ids =
          this.editableWord.dictionaries_ids.filter((dictId) => id !== dictId);
        return;
      }
      this.editableWord.dictionaries_ids.push(id);
    },
    addDictionary(id) {
      if (
        this.editableWord.dictionaries_ids &&
        this.editableWord.dictionaries_ids.indexOf(id) >= 0
      ) {
        return;
      }
      this.editableWord.dictionaries_ids.push(id);
    },
    removeDictionary(id) {
      this.editableWord.dictionaries_ids =
        this.editableWord.dictionaries_ids.filter((dictId) => id !== dictId);
    },
  },
};
</script>
