<template>
  <v-container>
    <v-card>
      <v-form ref="form" v-model="formValid" :lazy-validation="lazy">
        <v-row>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-subtitle>Abo-Typ:</v-list-item-subtitle>
              </v-list-item>
              <v-list-item
                v-for="plan in plans"
                :key="plan.stripeId"
                @click="planSelected(plan)"
              >
                <v-list-item-icon>
                  <v-icon
                    v-if="local_subscription.nickname == plan.nickname"
                    color="green"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ plan.name }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon></v-icon>
                </v-list-item-icon>
                <v-text-field
                  :disabled="local_subscription.nickname != 'institution'"
                  v-model="local_subscription.device_count"
                  @input="recalcPrice"
                  label="Anzahl Geräte"
                  required
                ></v-text-field>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-subtitle>Interval:</v-list-item-subtitle>
              </v-list-item>
              <v-list-item @click="setInterval('monthly')">
                <v-list-item-icon>
                  <v-icon
                    :color="
                      local_subscription.interval == 'monthly' ? 'green' : ''
                    "
                    >mdi-calendar</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-title>monatlich</v-list-item-title>
              </v-list-item>
              <v-list-item @click="setInterval('yearly')">
                <v-list-item-icon>
                  <v-icon
                    :color="
                      local_subscription.interval == 'yearly' ? 'green' : ''
                    "
                    >mdi-calendar-range</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-title>jährlich</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-subtitle>Laufzeit:</v-list-item-subtitle>
              </v-list-item>
              <v-list-item
                @click="
                  local_subscription.cancel_at_period_end =
                    !local_subscription.cancel_at_period_end
                "
              >
                <v-list-item-icon>
                  <v-icon
                    v-if="!local_subscription.cancel_at_period_end"
                    color="green"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  <v-icon v-else color="red"
                    >mdi-checkbox-blank-circle-outline</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-title>
                  Zum Ende der Laufzeit automatisch verlängern
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      label="Laufzeitende"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-subtitle>Preis:</v-list-item-subtitle>
              </v-list-item>
              <v-list-item @click="paid = !paid">
                <v-list-item-icon>
                  <v-icon v-if="paid" color="green"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                  <v-icon v-else color="red"
                    >mdi-checkbox-blank-circle-outline</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-title>Kostenpflichtig</v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>money</v-icon>
                </v-list-item-icon>
                <v-text-field
                  :disabled="!paid"
                  v-model="local_subscription.price"
                  label="Preis"
                  required
                ></v-text-field>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9"></v-col>
          <v-col cols="3">
            <v-btn color="success" @click="addSubscription">Buchen</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-expansion-panels :flat="true" v-if="debug">
        <v-expansion-panel>
          <v-expansion-panel-header>Rohdaten</v-expansion-panel-header>
          <v-expansion-panel-content>
            <code>{{ { local_subscription, plans, choosenPlan } }}</code>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    subscription: Object,
    uid: String,
  },
  data: function () {
    return {
      date: moment().add(1, "M").toISOString().substring(0, 10),
      menu1: false,
      paid: true,
      lazy: true,
      formValid: true,
      choosenPlan: null,
      local_subscription: {
        id: "eis-" + uuidv4(),
        cancel_at_period_end: true,
        cancel_at: null,
        canceled_at: null,
        current_period_end: null,
        current_period_start: Date(),
        device_count: 1,
        interval: "monthly",
        nickname: "basis",
        price: "4.95",
        livemode: true,
        payment_provider: "custom",
      },
    };
  },
  /* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
  computed: {
    ...mapGetters({
      plans: "customSubscription/plans",
      debug: "navigation/debug",
    }),
    computedPrice() {
      const prices = {
        "basis-monthly": 4.95,
        "basis-yearly": 49.95,
        "family-monthly": 9.95,
        "family-yearly": 99.95,
        "institution-yearly": 99.95,
      };

      let price =
        prices[
          this.local_subscription.nickname +
            "-" +
            this.local_subscription.interval
        ];

      if (this.local_subscription.nickname === "institution") {
        price = this.local_subscription.device_count * price;
      }

      return (Math.round(price * 100) / 100).toFixed(2);
      // return true;
    },
    computedDateFormatted() {
      return moment(this.date).format("DD.MM.YYYY");
      //   return this.formatDate(this.date);
    },
    computedDateTime() {
      return moment(this.date);
      //   return this.formatDate(this.date);
    },
  },
  created: function () {
    // this.$store.dispatch("setCurrentView", "Login");
    this.local_subscription.current_period_end = this.computedDateTime;
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.local_subscription.current_period_end = this.computedDateTime;
    },
  },
  methods: {
    recalcPrice() {
      this.local_subscription.price = this.computedPrice;
    },

    setInterval(mode) {
      console.log("mode", mode);
      if (this.local_subscription.nickname === "institution") {
        mode = "yearly";
      }
      const calUnit = mode === "yearly" ? "y" : "M";
      this.local_subscription.interval = mode;
      this.date = moment().add(1, calUnit).toISOString().substring(0, 10);
      this.local_subscription.price = this.computedPrice;
    },

    dateFromTimestamp(stamp) {
      return moment.unix(stamp).format("DD.MM.YYYY");
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price / 100) + " €";
    },
    addSubscription() {
      this.$store.dispatch("customSubscription/logPlans");
      console.log(this.local_subscription);
      this.$store.dispatch("customSubscription/addCustomSubscription", {
        subscription: this.local_subscription,
        uid: this.uid,
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    planSelected(plan) {
      this.local_subscription.nickname = plan.nickname;
      this.local_subscription.device_count = plan.deviceCount;
      this.local_subscription.product = { name: plan.name };
      this.local_subscription.price = this.computedPrice;
      if (plan.nickname === "institution") {
        this.setInterval("yearly");
      }
    },
  },
};

/**

custom_payment: {
    id: stripeSubscription.id,
    cancel_at_period_end: true,
    cancel_at: null,
    canceled_at: null,
    current_period_end: timestamp,
    current_period_start: now,
    customer: stripeSubscription.customer,
    interval: stripeSubscription.plan.interval,
    nickname: stripeSubscription.plan.nickname,
    price: stripeSubscription.plan.amount,
    product: stripeSubscription.product,
    livemode: stripeSubscription.livemode,
    payment_provider: "custom"
};

*/
</script>
